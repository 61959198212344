import React from 'react'

class RadioButtonsTemplate extends React.Component{     
    constructor(props) {
        super(props);        
        this.state = {responses:this.props.previous_answers?this.props.previous_answers:[]}
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(input_ref, index){
        const value = input_ref.current.checked = !input_ref.current.checked;
        this.setState(state=>{
                let responses = [];             
                responses[index] = {checked:value}
                return {responses}
            }
        );
    }
    render(){
        return (
           <>
                {
                    this.props.questions.map((question, index)=>{
                        let checked = this.state.responses[index]?this.state.responses[index].checked:false;
                        const input_ref = React.createRef();
                        return(
                            
                            <div key={index} className={checked?"option-group flexbox selected":"option-group flexbox"} onClick={()=>{this.handleChange(input_ref, index)}}>
                                <input type="radio" name="radio-template" value={question.value} checked={checked} ref={input_ref} onChange={()=>{this.handleChange(input_ref, index)}}/>
                                <div className="option-copy">
                                    <strong>{question.header}</strong>
                                    <small>{question.text}</small>
                                </div>                         
                            </div>
                        )
                    })
                }
           </>
        )
    }
}

export default RadioButtonsTemplate;