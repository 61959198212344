import React from 'react'
import CheckboxTemplate  from './questions_templates/CheckboxTemplate';
import RadioButtonsTemplate  from './questions_templates/RadioButtonsTemplate';
 
const container_ref = React.createRef();

const getQuestionTemplate=(template_type)=>{
    let templates = {
      checkbox_template: CheckboxTemplate ,
      radiobuttons_template: RadioButtonsTemplate 
    };

    if(container_ref.current){
        container_ref.current.scrollTo(0,0);
    }

    return templates[template_type];
  }

const QuestionsContainer=(props)=>{
    let current_question = props.eco_questions[props.current_index];
    let QuestionTemplate = getQuestionTemplate(current_question.template_type);
    let previous_answers = props.questions_answers[props.current_index.toString()]?props.questions_answers[props.current_index.toString()]:[];

    return(        
        <section className="content-section align-center-flex survey-form" ref={container_ref}>
            <div className="container">
                <div className="back-button">
                    <a href="#" onClick={(e)=>{e.preventDefault();props.changeQuestion('-')}}>
                        <img src="/img/icon-left.svg" alt="Back" /> {props.current_index + 1} / {props.eco_questions.length}
                    </a>
                </div>
                <h1>{current_question.header}</h1>
                <h2>{current_question.subheader}</h2>
                <form className="col-8" onSubmit={props.answerQuestion}> 
                    <div className="survey-options">                                            
                        {<QuestionTemplate key={props.current_index} questions={current_question.questions}  previous_answers={previous_answers} />}                        
                    </div>
                    <div className="container cta-holder">
                        <button type='submit' className="cta">{current_question.button_text}</button>
                    </div>                   
                </form>  
            </div>                
        </section>        
    )
} 

export default QuestionsContainer;