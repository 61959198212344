const calculateQuestionMaxScore=(current_question)=>{    
    if(current_question.template_type === "checkbox_template"){
        let score = 0;    
        current_question.questions.map((question)=>{
            score += parseInt(question.value);   
        })    
        return score; 
    }else if(current_question.template_type === "radiobuttons_template"){
        let max_value = 0 
        current_question.questions.map((question)=>{
            let question_value = parseInt(question.value);
            if(max_value < question_value){
                max_value = question_value;
            }    
        })
        return max_value;
    }    
}

const calculateAnswerScore=(current_answer)=>{
    let score = 0;     
    current_answer.map((question_answer)=>{
        if(question_answer.checked){
            score += parseInt(question_answer.value);
        }
    })
    return score;
}

const calculateScore=(questions, answers)=>{    
    let response = {
        max_score: 0,
        answers_score: 0
    }

    for(let question_index in questions){
        let current_question = questions[question_index];
        let current_answer = answers[question_index];

        let question_max_score = calculateQuestionMaxScore(current_question);        
        let current_answer_score = calculateAnswerScore(current_answer);

        response.max_score += question_max_score;
        response.answers_score += current_answer_score;         
    }

    return response;
}

export const calculateFinalScore=(answers)=>{
    let total_max = answers.max_score + (answers.max_score * 0.41);
    let user_score = answers.answers_score + (answers.answers_score * 0.41);
    let score = parseInt(user_score/total_max * 100);
    return score;
  }


export default calculateScore;