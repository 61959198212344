import React from "react";
import { Link } from "gatsby";

const calculateProgressBarWidth = (current_index, questions_number) => {
  let length = (current_index * 100) / questions_number;
  return length;
};

const Navbar = ({ progress, content }) => {
  let progres_bar_length = 0;
  if (progress) {
    progres_bar_length = calculateProgressBarWidth(
      progress.current_index,
      progress.questions
    );
  }

  return (
    <header>
      <div className="container flexbox space-between">
        <Link to="/" className="logo">
          <img src="/img/logo.png" alt="Logo" height="40" />
        </Link>
        {progress && (
          <div className="survey-progress">
            <div className="progress">
              <div
                className="progress-bar"
                style={{ width: `${progres_bar_length}%` }}
              />
            </div>
            <strong>
              {progress.current_index} / {progress.questions}{" "}
              <span className="mobile-hide">{content.questions_done}</span>
            </strong>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
