import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import QuestionsContainer from '../components/QuestionsContainer'
import FormContainer from '../components/FormContainer'
import calculateScore from '../components/utils';
import ReactDOM from 'react-dom'
class QuestionsPage extends React.Component{ 
  constructor(props){
    super(props);
    this.state={
      current_question_index: 0,
      show_form: false,
      questions_answers: []
    }
    this.answers_statistics = {};    
    this.eco_questions = this.props.data.markdownRemark.frontmatter.eco_questions;
    this.progress_bar_content = this.props.data.markdownRemark.frontmatter.progress_bar;
    this.changeQuestion = this.changeQuestion.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.goBack = this.goBack.bind(this);  
   
    if (typeof window !== 'undefined'){
        window.localStorage.setItem('quiz_started', 'true')      
    }    
  }
  componentDidUpdate() {
    try{
      window.scrollTo(0, 0)
    }finally{
      
    }
  }
  changeQuestion(direction){
    let steps = {
        "-" : this.state.current_question_index - 1,
        "+" : this.state.current_question_index + 1,
    }
    let current_index = steps[direction];
    if(current_index === -1){       
        return;
    }else if(current_index === this.eco_questions.length){ 
      this.forceUpdate(()=>{
        let statistics = calculateScore(this.eco_questions, this.state.questions_answers);
        this.answers_statistics = statistics;        
        this.setState({show_form: true});
      })      
      
      return;       
    }     
    this.setState((state)=>({current_question_index: current_index}));
  }       

  answerQuestion(response){    
    response.preventDefault();
    let responses = [];     
    for(var element of response.currentTarget.elements) {             
        if(element.type === 'submit'){
           continue;     
        }             
        // const is_checked = (element.type === 'checkbox' || element.type === 'radio') ? element.checked : element.value;          
        responses.push({value: element.value, checked: element.checked});             
    }
    this.setState(
        (state)=>{
            state.questions_answers[state.current_question_index.toString()] = responses;                 
            return {questions_answers: state.questions_answers}
        }
    );
    this.changeQuestion('+');         
  } 

  goBack(){     
    this.setState({show_form: false});
  }

  render(){
    const progress=this.state.show_form?null:{questions: this.eco_questions.length, current_index: this.state.current_question_index};
    return (
      <Layout progress={progress} content={this.progress_bar_content}  >
        {!this.state.show_form && <QuestionsContainer
                                    eco_questions={this.eco_questions}                                   
                                    current_index={this.state.current_question_index}
                                    questions_answers={this.state.questions_answers}
                                    changeQuestion={this.changeQuestion}
                                    answerQuestion={this.answerQuestion}
                                  />}
        {this.state.show_form && <FormContainer answers={this.answers_statistics} goBack={this.goBack} />} 
      </Layout>
    )
  } 
}

export default QuestionsPage

export const questionPageQuery = graphql`
  query QuestionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {       
      frontmatter {
        eco_questions{
          header           
          subheader
          template_type
          button_text
          questions{
            header
            value
          }
        }
        progress_bar{
            questions_done
        }
      }
    }
  }
`
