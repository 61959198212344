import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer>
    <div className="container">
      &copy; 2018 Mitt klimatlöfte. All rights reserved. <br />
      <Link to="/privacy-policy">Personuppgiftspolicy</Link>{" "}
      <span className="separator">&nbsp; | &nbsp;</span>
      <Link to="/terms-of-use">Användarvillkor på Mittklimatlöfte</Link>
    </div>
  </footer>
);

export default Footer;
