import React from "react";
import { navigate, StaticQuery, graphql } from "gatsby";
import sendEmail from "./api_requests";
import { calculateFinalScore } from "./utils";
import { Form, Input, Button, Checkbox, Alert } from "antd";
const FormItem = Form.Item;

const FormQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "questions-page" } }) {
      frontmatter {
        form_texts {
          link_text
          header
          form_name_placeholder
          form_email_placeholder
          form_occupation_placeholder
          full_name_error
          email_error
          occupation_error
          terms_of_use_error
          submit_button
          agreement_text
          terms_of_use
          privacy_policy
        }
      }
    }
  }
`;

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.content = this.props.content;
    this.state = { request_status: "", error_message: null };
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.request_status === "sending") {
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ request_status: "sending" });
        sendEmail(values)
          .then(this.handleSuccessEvent.bind(this))
          .catch(this.handleErrorEvent.bind(this));
      }
    });
  };

  handleSuccessEvent = response => {
    if (response.status === "ok") {
      this.goToNextStep();
    } else {
      console.log("error: ", response);
      this.setState({ request_status: "" });
      if ("message" in response.data) {
        if (response.data.message == "Member Exists") {
          this.goToNextStep();
        } else {
          this.setState({ error_message: response.data.message });
        }
      }
    }
  };

  goToNextStep = () => {
    let score = calculateFinalScore(this.props.answers);
    if (score == 0) {
      navigate("/failed-test");
    } else {
      navigate(`/certificate/${score}`);
    }
  };

  handleErrorEvent = response => {
    this.setState({ request_status: "" });
    console.log("error: ", response);
  };

  checkCheckBox = (rule, value, callback) => {
    const form = this.props.form;
    if (value == undefined) {
      callback();
    }

    if (!value) {
      callback(this.content.terms_of_use_error);
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <section className="content-section align-center-flex survey-form">
        <div className="container">
          {this.state.error_message && (
            <Alert
              message={this.state.error_message}
              type="error"
              closable
              afterClose={() => {
                this.setState({ error_message: null });
              }}
            />
          )}

          <Form onSubmit={this.handleSubmit} className="col-8 user-data-form">
            <div className="back-button" onClick={this.props.goBack}>
              <a href="#">
                <img src="/img/icon-left.svg" alt="Back" />{" "}
                {this.content.link_text}
              </a>
            </div>
            <h1>{this.content.header}</h1>
            <div className="survey-options">
              <FormItem className="data-form-item">
                {getFieldDecorator("full_name", {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: this.content.full_name_error
                    }
                  ]
                })(
                  <Input
                    type="text"
                    name="full_name"
                    className="basic-input"
                    placeholder={this.content.form_name_placeholder}
                  />
                )}
              </FormItem>
              <FormItem className="data-form-item">
                {getFieldDecorator("user_email", {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: this.content.email_error
                    }
                  ]
                })(
                  <Input
                    type="email"
                    name="email"
                    className="basic-input"
                    placeholder={this.content.form_email_placeholder}
                  />
                )}
              </FormItem>
              <FormItem className="data-form-item">
                {getFieldDecorator("occupation", {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: this.content.occupation_error
                    }
                  ]
                })(
                  <Input
                    type="text"
                    name="occupation"
                    className="basic-input"
                    placeholder={this.content.form_occupation_placeholder}
                  />
                )}
              </FormItem>
              <FormItem className="agreement-check">
                {getFieldDecorator("agreement", {
                  rules: [
                    {
                      required: true,
                      message: this.content.terms_of_use_error
                    },
                    {
                      validator: this.checkCheckBox
                    }
                  ]
                })(
                  <Checkbox>
                    {this.content.agreement_text}{" "}
                    <a href="/terms-of-use" target="_blank">
                      {this.content.terms_of_use}
                    </a>{" "}
                    and{" "}
                    <a href="/privacy-policy" target="_blank">
                      {this.content.privacy_policy}
                    </a>
                  </Checkbox>
                )}
              </FormItem>
            </div>
            <FormItem className="container cta-holder">
              <Button
                type="primary"
                htmlType="submit"
                className="cta"
                loading={this.state.request_status === "sending" ? true : false}
              >
                {this.content.submit_button}
              </Button>
            </FormItem>
          </Form>
        </div>
      </section>
    );
  }
}

const UserDetailsForm = Form.create()(FormContainer);

const FormDecorator = props => (
  <StaticQuery
    query={FormQuery}
    render={data => {
      const content = data.markdownRemark.frontmatter.form_texts;
      return <UserDetailsForm content={content} {...props} />;
    }}
  />
);

export default FormDecorator;
