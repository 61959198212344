import {EMAIL_ENDPOINT_URL} from './config';

// sendEmail(payload).then(this.handleSuccessEvent.bind(this)).catch(this.handleErrorEvent.bind(this)); example of implementation /mk


const sendEmail=(payload)=>{
  payload.client_id = 'ecoscore';

  return fetch(EMAIL_ENDPOINT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }).then(function(response) {
      return response.json()
  })
}

export default sendEmail;